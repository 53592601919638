
















import { Component, Provide, Vue } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import { SummaryViewModel } from '../viewmodel/summary-viewmodel'

@Observer
@Component({
  components: {
    BannerProjectTable: () => import('../components/banner-project-table.vue'),
    FundedProjectTable: () => import('../components/funded-project-table.vue'),
    UpComingProjectTable: () => import('../components/upcoming-project-table.vue'),
  },
})
export default class Summary extends Vue {
  @Provide() vm = new SummaryViewModel()

  destroyed() {
    this.vm.destroy()
  }
}
