import { observable, action, reaction, computed, when, IReactionDisposer } from 'mobx'
import { poolsStore } from '@/stores/pools-store'
import moment from 'moment'
import { PoolStore } from '@/stores/pool-store'
import { asyncAction } from 'mobx-utils'
import { sortBy, toNumber } from 'lodash-es'
import { tierHelper, Zero } from '@/constants'
import { StakingHandler } from '@/blockchainHandler/stakingHandler'
import { FixedNumber } from 'ethers'
import { walletStore } from '@/stores/wallet-store'
import { bnHelper } from '@/helper/bignumber-helper'

export interface Project {
  status: string
  startTime?: number
  endTime?: number
}

export class SummaryViewModel {
  @observable loading = false

  @observable upComingProjects: PoolStore[] = []
  @observable upComingNumberItemDisplay = 3
  @observable upComingMoreItem = 3

  //funded project
  @observable fundedProjects: PoolStore[] = []
  @observable fundedNumberItemDisplay = 2
  @observable fundedMoreItem = 2

  @observable mostRecentFilter = false
  @observable totalRaisedFilter = false
  @observable textSearch = ''
  @observable stakingHadler?: StakingHandler = undefined
  @observable totalStakedAmount: FixedNumber = Zero
  @observable userStakedAmount: FixedNumber = Zero
  @observable userTierIndex?: number = 0

  private _disposers: IReactionDisposer[] = []
  constructor() {
    this.loadStakingContract()
    this.fetchPools()
    this._disposers = [
      reaction(
        () => walletStore.account,
        (x) => {
          this.clearStakingUserInfo()
          if (x) {
            this.fetchStakingUserInfo()
          }
        },
        {
          fireImmediately: true,
        }
      ),
    ]
  }

  destroy() {
    this._disposers.forEach((d) => d())
  }

  @action clearStakingUserInfo() {
    this.userTierIndex = 0
    this.userStakedAmount = Zero
  }

  @asyncAction *loadStakingContract() {
    try {
      this.stakingHadler = new StakingHandler()
      yield this.stakingHadler.loadData()
      this.totalStakedAmount = this.stakingHadler.amount
    } catch (e) {
      console.log('sumary - loadStakingContract failed')
    }
  }

  @asyncAction *fetchStakingUserInfo() {
    yield when(() => !!this.stakingHadler)
    try {
      const { amount, tier } = yield this.stakingHadler?.getUserInfo(walletStore.account)
      this.userStakedAmount = amount
      this.userTierIndex = tier
    } catch (e) {
      console.log('sumary - fetchStakingUserInfo failed')
    }
  }

  @asyncAction *fetchPools() {
    try {
      this.loading = true
      yield poolsStore.fetchPools()
      this.upComingProjects = poolsStore.validPools.filter((p) => !p.pool.status || p.pool.status === 'upcoming')
      this.fundedProjects = poolsStore.validPools.filter((p) => !p.pool.status || p.pool.status === 'funded')
    } catch (error) {
      //
    } finally {
      this.loading = false
    }
  }

  @computed get isStakedLCH() {
    return bnHelper.gt(this.userStakedAmount, Zero)
  }

  /**
   * Up Coming Project Display
   * @param val
   */

  @computed get upComingItemDisplayed() {
    return this.upComingProjects.slice(0, this.upComingNumberItemDisplay)
  }

  @computed get userName() {
    return walletStore?.userInfo?.userName
  }

  @action.bound onUpComingMoreItem() {
    this.upComingNumberItemDisplay = this.upComingNumberItemDisplay + this.upComingMoreItem
  }

  /**
   * Funded Project Display
   * @param val
   */
  @action.bound onMostRecentFundedChange(val: boolean) {
    this.mostRecentFilter = val
    this.fundedNumberItemDisplay = 2
  }

  @action.bound onTotalRaisedFundedChange(val: boolean) {
    this.totalRaisedFilter = val
    this.fundedNumberItemDisplay = 2
  }

  @action.bound onChangeSearchText(textSearch: string) {
    this.textSearch = textSearch
    this.fundedNumberItemDisplay = 2
  }

  @action.bound onFundedMoreItem(): void {
    this.fundedNumberItemDisplay = this.fundedNumberItemDisplay + this.fundedMoreItem
  }

  @computed get slicedFundedProject() {
    let fundedProjects: PoolStore[] = this.fundedProjects

    if (this.mostRecentFilter && this.totalRaisedFilter) {
      //
    }

    if (this.mostRecentFilter && !this.totalRaisedFilter) {
      fundedProjects = sortBy(fundedProjects, (funded) => moment(funded.pool.data?.whitelistConfig?.claimStart))
    }

    if (!this.mostRecentFilter && this.totalRaisedFilter) {
      fundedProjects = sortBy(fundedProjects, (funded) => toNumber(funded.pool.totalRaise))
    }

    return fundedProjects
  }

  @computed get fundedItemDisplayed() {
    return this.slicedFundedProject.slice(0, this.fundedNumberItemDisplay)
  }

  @computed get tierList() {
    return tierHelper.getTierList()
  }
}
